import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const baseTheme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
        color: Colors.black,
        h1: {
            fontSize: '4.2rem',
            fontWeight: 700,
            color: Colors.black,
        },
        h2: {
            fontSize: '3.2rem',
            fontWeight: 700,
            fontStyle: 'normal',
            color: Colors.black,
        },
        h3: {
            fontSize: '2.4rem',
            fontWeight: 700,
            lineHeight: '3.3rem',
            color: Colors.black,
        },
        h4: {
            fontSize: '1.8rem',
            fontWeight: 700,
            lineHeight: '2.5rem',
            color: Colors.black,
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 800,
            color: Colors.teal,
        },
        body1: {
            fontSize: '1.7rem',
            fontWeight: 400,
            color: Colors.darkGray,
            fontFamily: "'acumin-pro', sans-serif",
            lineHeight: '2.4rem',
        },
        body2: {
            fontSize: '1.8rem',
            fontWeight: 400,
            lineHeight: '2.6rem',
            color: Colors.black,
        },
        button: {
            fontSize: '1.5rem',
            fontWeight: 700,
            textTransform: 'none',
        },
    },
    spacing: 5,
    palette: {
        // text: {},
        primary: {
            main: Colors.black,
        },
        // secondary: {},
        // tertiary: {},
        // quaternary: {},
        // background: {},
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '3.8rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '3.4rem',
            },
        },
        h2: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '2.8rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '2.6rem',
            },
        },
        h3: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '2.2rem',
                lineHeight: '3.1rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '2.0rem',
                lineHeight: '2.8rem',
            },
        },
        h4: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '1.8rem',
                lineHeight: '2.5rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '1.6rem',
                lineHeight: '2.4rem',
            },
        },
        body1: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '1.6rem',
                lineHeight: '2.2rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '1.7rem',
                lineHeight: '2.0rem',
            },
            '& a': {
                fontWeight: 600,
                color: Colors.teal,
                textDecoration: 'none',
            },
        },
        body2: {
            [baseTheme.breakpoints.down('desktop')]: {
                fontSize: '1.7rem',
                lineHeight: '2.5rem',
            },
            [baseTheme.breakpoints.down('tablet')]: {
                fontSize: '1.6rem',
                lineHeight: '2.2rem',
            },
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    color: Colors.darkGray,
                    padding: '5px 20px',
                    marginBottom: 15,
                    borderRadius: 12,
                    '& a': {
                        fontWeight: 600,
                        color: Colors.teal,
                    },
                    '& li': {
                        marginTop: 10,
                        marginRight: 30,
                    },
                    '&:last-of-type': {
                        borderRadius: 12,
                    },
                    '&:before': {
                        display: 'none',
                    },
                    '& .Mui-expanded': {
                        // marginTop: 0,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    flexDirection: 'column',
                },
                expandIconWrapper: {
                    color: Colors.teal,
                    fill: Colors.teal,
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {},
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: 20,
                        border: `1px solid ${Colors.black}`,
                        backgroundColor: 'rgba(255,255,255,0.3)',
                    },
                    '& svg': {
                        fill: '#112233',
                        color: '#112233',
                        opacity: 1,
                    },
                },
                select: {
                    zIndex: 1,
                    padding: '8px 20px',
                    paddingTop: 6,
                    // height: 40,
                    // justifyContent: 'center',
                    // backgroundColor: 'rgba(255,255,255,0.3)',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {},
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {},
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {},
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {},
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {},
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {},
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: {},
                elevation8: {
                    borderRadius: 20,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {

                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: baseTheme.typography.fontFamily,
                    fontWeight: 700,
                    fontSize: '1.6rem',
                    padding: '8px 25px',
                    margin: '0px 10px',
                    minHeight: 'fit-content',
                    '&:hover, :focus': {
                        borderRadius: 17,
                        backgroundColor: `rgba(${Colors.tealRGB}, 0.2)`,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 44,
                    paddingLeft: 25,
                    paddingRight: 25,
                    borderRadius: 22,
                    '& .MuiSvgIcon-root': {
                        marginLeft: -5,
                        marginRight: 7,
                    },
                    '&.MuiButton-containedPrimary': {
                        '&:hover': {
                            backgroundColor: Colors.darkGray,
                        },
                    },
                    '&.MuiButton-containedSecondary': {
                        height: 44,
                        backgroundColor: Colors.white,
                        borderRadius: 20,
                        paddingLeft: 10,
                        fontSize: 14,
                        color: Colors.darkGray,
                        fontWeight: 600,
                        border: `2px solid ${Colors.white}`,
                        '&:hover': {
                            backgroundColor: Colors.white,
                            border: `2px solid ${Colors.black}`,
                        },
                    },
                    '&.Mui-disabled': {
                        color: Colors.white,
                        backgroundColor: '#b2b2b2',
                    },
                },
                text: {
                    color: Colors.teal,
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                    '&& .MuiTouchRipple-child': {
                        backgroundColor: Colors.darkGray,
                    },
                },
                textSecondary: {
                    backgroundColor: 'inheirt !important',
                    borderRadius: 0,
                    color: Colors.black,
                    marginBottom: 4,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginLeft: 15,
                    marginRight: 15,
                    borderBottom: `4px solid rgba(${Colors.tealRGB}, 0)`,
                    '&:hover': {
                        textDecoration: 'none',
                        borderBottom: `4px solid ${Colors.black}`,
                    },
                    '&& .MuiTouchRipple-child': {
                        backgroundColor: Colors.darkGray,
                    },
                    '&.active': {
                        borderBottom: `4px solid ${Colors.black}`,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused, & .Mui-error': {},
                },
                shrink: {
                    transform: 'translate(0, 1.5px) scale(1)',
                },
                asterisk: {},
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: Colors.black,
                },
            },
        },
    },
});

export default theme;
