import WebClient from '../utils/web-client';
import AppTypes from '../action-types/app';

export const GetGoalsBegin = () => ({
    type: AppTypes.GET_GOALS_BEGIN,
});
const GetGoalsSuccess = (payload) => ({
    type: AppTypes.GET_GOALS_SUCCESS,
    payload,
});
const GetGoalsError = (errorMsg) => ({
    type: AppTypes.GET_GOALS_ERROR,
    payload: { errorMsg },
});

export const GetGoals = () => async (dispatch) => {
    dispatch(GetGoalsBegin());
    try {
        const { data } = await WebClient.get('/goals');
        dispatch(GetGoalsSuccess(data));
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(GetGoalsError(errorType));
    }
};

export const GetStateDataBegin = () => ({
    type: AppTypes.GET_STATES_DATA_BEGIN,
});
const GetStateDataSuccess = (payload) => ({
    type: AppTypes.GET_STATES_DATA_SUCCESS,
    payload,
});
const GetStateDataError = (errorMsg) => ({
    type: AppTypes.GET_STATES_DATA_ERROR,
    payload: { errorMsg },
});

export const GetStateData = () => async (dispatch) => {
    dispatch(GetStateDataBegin());
    try {
        const { data } = await WebClient.get('/goals/states');
        dispatch(GetStateDataSuccess(data));
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(GetStateDataError(errorType));
    }
};
