import AppTypes from '../action-types/app';

const internals = {
    initial: () => ({
        goals: null,
        goalsStatus: {
            error: false,
            errorMsg: null,
            completed: false,
        },
        stateData: null,
        stateDataStatus: {
            error: false,
            errorMsg: null,
            completed: false,
        },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AppTypes.GET_STATES_DATA_BEGIN:
        return {
            ...state,
            stateData: null,
            stateDataStatus: {
                completed: false,
                error: false,
                errorMsg: null,
            },
        };
    case AppTypes.GET_STATES_DATA_SUCCESS:
        return {
            ...state,
            stateData: payload,
            stateDataStatus: {
                completed: true,
                error: false,
                errorMsg: null,
            },
        };
    case AppTypes.GET_STATES_DATA_ERROR:
        return {
            ...state,
            stateDataStatus: {
                completed: true,
                error: true,
                errorMsg: payload.errorMsg,
            },
        };
    case AppTypes.GET_GOALS_BEGIN:
        return {
            ...state,
            goals: null,
            goalsStatus: {
                completed: false,
                error: false,
                errorMsg: null,
            },
        };
    case AppTypes.GET_GOALS_SUCCESS:
        return {
            ...state,
            goals: [...payload.goals],
            goalsStatus: {
                completed: true,
                error: false,
                errorMsg: null,
            },
            dataArchive: payload.dataArchive,
            fullReport: payload.fullReport,
            scoring: payload.scoring,
        };
    case AppTypes.GET_GOALS_ERROR:
        return {
            ...state,
            goalsStatus: {
                completed: true,
                error: true,
                errorMsg: payload.errorMsg,
            },
        };
    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
