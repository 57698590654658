export default {
    white: '#ffffff',
    black: '#000000',
    lightGray: '#F1F1F1',
    darkGray: '#333333',
    teal: '#087E8B',
    tealRGB: '8,126,139',
    orange: '#E77823',
    orangeRGB: '231, 120, 35',
    red: '#9F0E04',
    redRGB: '159,14,4',
    yellow: '#F4C651',
    yellowRGB: '244,198,81',
    moss: '#BBBE81',
    mossRGB: '187,190,129',
    error: '#9F0E04',
    success: '#13AE47',
    green: '#0B7F8C',
};
