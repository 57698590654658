import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';

const Home = lazy(() => import('../../routes/home'));
const About = lazy(() => import('../../routes/about'));
const Privacy = lazy(() => import('../../routes/privacy'));
const Terms = lazy(() => import('../../routes/terms'));
const Methodology = lazy(() => import('../../routes/methodology'));
const Faqs = lazy(() => import('../../routes/faqs'));
const ConsumerResources = lazy(() => import('../../routes/consumer-resources'));
const StatePage = lazy(() => import('../../routes/state'));
const Error404 = lazy(() => import('../../routes/error/404'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <main>
            <Routes>
                <Route path="/error/404" element={<Error404 />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/methodology" element={<Methodology />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/resources" element={<ConsumerResources />} />
                <Route path="/state/:selectedState" element={<StatePage />} />
                <Route path="/" element={<Home />} />
                <Route path="/*" element={<Navigate replace to="/error/404" />} />
            </Routes>
        </main>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
