import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

// REDUX
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import ErrorFallback from './components/error-fallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import reducers from './reducers';

import App from './routes';

import Initializers from './initializers';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import GoogleAnalytics from './components/google-analytics';

const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['app'],
};
const persistedReducer = persistReducer(persistConfig, reducers());
const store = createStore(
    persistedReducer,
    initialState,
    composedEnhancers,
);
const persistedStore = persistStore(store);

const onBeforeLift = () => {
    // Run initializers... anything that will need to use or subscribe to the store
    Initializers(store);
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistedStore}
            onBeforeLift={onBeforeLift}
        >
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                    <ScrollToTop />
                    <GoogleAnalytics />
                    <App store={store} />
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
