const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_STATES_DATA_BEGIN: true,
    GET_STATES_DATA_SUCCESS: true,
    GET_STATES_DATA_ERROR: true,
    GET_GOALS_BEGIN: true,
    GET_GOALS_SUCCESS: true,
    GET_GOALS_ERROR: true,
});
